import { mapGetters, mapMutations } from "vuex";
import { changePassword } from "@lib";
export default {
  name: "i-header",
  props: ["type"],
  computed: {
    ...mapGetters(["userInfo"]),
    hasUser() {
      return !!this.userInfo;
    },
    orgName() {
      return this.userInfo?.orgName;
    },
    active() {
      return this.$route.path.replace("/", "").split("/");
    },
    menu() {
      switch (this.type) {
        case "developer":
          return Object.freeze([
            {
              id: "accountList",
              name: "账户管理",
              icon: "el-icon-user",
              to: {
                name: "accountList",
              },
            },
            /* {
              id: "applicationBase",
              name: "应用配置",
              icon: "el-icon-setting",
              to: {
                name: "applicationBase",
              },
            }, */
            /* {
              id: "document",
              name: "文档中心",
              icon: "el-icon-document",
              to: {
                name: "document",
              },
            }, */
          ]);
        case "supplier":
          return Object.freeze([
            {
              id: "solution",
              name: "解决方案",
              icon: "el-icon-document-checked",
              to: {
                name: "solutionList",
              },
            },
            {
              id: "apply",
              name: "应用接入",
              icon: "el-icon-menu",
              to: {
                name: "applyGaishu",
              },
            },
          ]);
        default:
          return [];
      }
    },
    submenu() {
      switch (this.active[0]) {
        case "application":
          return Object.freeze([
            /* {
              id: "applicationBase",
              name: "基础数据",
              to: {
                name: "applicationBase",
              },
            }, */
            {
              id: "applicationData",
              name: "旅游大数据",
              to: {
                name: "applicationData",
              },
            },
          ]);
        case "solution":
          return Object.freeze([
            {
              id: "list",
              name: "产品介绍&解决方案",
              to: {
                name: "solutionList",
              },
            },
          ]);
        case "apply":
          return Object.freeze([
            {
              id: "gaishu",
              name: "概述",
              to: {
                name: "applyGaishu",
              },
            },
            {
              id: "list",
              name: "应用注册",
              to: {
                name: "applyList",
              },
            },
          ]);
        default:
          return [];
      }
    },
  },
  data() {
    return {
      editForm: {
        oldPwd: "",
        newPwd: "",
        checkPwd: "",
      },
      editFormRules: {
        oldPwd: {
          required: true,
          message: "请输入原密码",
          trigger: "blur",
        },
        newPwd: {
          required: true,
          message: "请输入新密码",
          trigger: "blur",
        },
        checkPwd: {
          required: true,
          message: "请输入确认密码",
          trigger: "blur",
        },
      },
      editShow: false,
    };
  },
  methods: {
    ...mapMutations(["logOut"]),
    changeItem(val) {
      if (val == "edit") {
        this.editShow = true;
      } else if (val == "personal") {
        this.personalShow = true;
      } else if (val == "exit") {
        this.exitShow = true;
      } else if (val == "tuichu") {
        sessionStorage.clear();
        this.logOut();
        this.$router.replace("/login");
      }
    },
    async editPasFn() {
      let { status } = await changePassword({
        newPassword: this.editForm.newPwd,
        password: this.editForm.oldPwd,
        phone: this.userInfo.loginName,
      });
      if (status === "success") {
        this.editShow = false;
        this.$message.success("密码修改成功!请重新登录");
        this.logOut();
        this.$router.replace({ name: "login" });
      } else {
        this.$message.error("密码修改失败，请检查重新输入");
        this.editForm.oldPwd = "";
        this.editForm.newPwd = "";
        this.editForm.checkPwd = "";
      }
    },
  },
};
