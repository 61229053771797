/*
 * @Author: your name
 * @Date: 2022-04-12 16:02:23
 * @LastEditTime: 2022-04-25 09:01:07
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /惠旅云底座/huilvyun_kaifangzhePlatform/src/lib/config.js
 */

export const oldBase = "/developer";
// export const oldBase = "/developerApi";

// export const Base = "/developerApi";
export const Base = "/developer";
