import axios from "axios";
import store from "@store";
import router from "@router";
let http = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
  baseURL: process.env.NODE_ENV === "development" ? "" : window.baseURL,
  timeout: 30000,
});
// 请求前
http.interceptors.request.use(
  (config) => {
    if (store.getters["token"]) {
      config.headers.token = store.getters["token"];
      config.headers.loginName = store.getters["userInfo"]?.loginName;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error.data);
  }
);
// 请求后
http.interceptors.response.use(
  (response) => Promise.resolve(response.data),
  (error) => {
    const { response } = error;
    if (response.status === 401 || response.status === 403) {
      store.commit("logOut");
      router.replace({ name: "login" });
    }
    return Promise.reject(error.data);
  }
);
export const Get = function (url, params, config) {
  return http.get(url, { params }, config);
};
export const Post = (url, params, config = {}) => {
  return http.post(url, params, config);
};
let http1 = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
  baseURL: process.env.NODE_ENV === "development" ? "" : window.IMGURL,
  timeout: 30000,
});
// 请求前
http1.interceptors.request.use(
  (config) => {
    if (store.getters["token"]) {
      config.headers.token = store.getters["token"];
      config.headers.loginName = store.getters["userInfo"]?.loginName;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error.data);
  }
);
// 请求后
http1.interceptors.response.use(
  (response) => Promise.resolve(response.data),
  (error) => {
    const { response } = error;
    if (response.status === 401 || response.status === 403) {
      store.commit("logOut");
      router.replace({ name: "login" });
    }
    return Promise.reject(error.data);
  }
);
export const Upload = (url, params, config = {}) => {
  return http1.post(url, params, config);
};
