/*
 * @Author: your name
 * @Date: 2022-04-11 14:00:24
 * @LastEditTime: 2022-06-27 15:42:06
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /惠旅云底座/huilvyun_kaifangzhePlatform/src/main.js
 */
import Vue from "vue";
import App from "./App.vue";
import router from "@router/index";
import store from "@store/index";
import ElementUI from "element-ui";
import minix from "./utils/minix";
import "element-ui/lib/theme-chalk/index.css";

// import VueQuillEditor from "vue-quill-editor";
// import "quill/dist/quill.core.css";
// import "quill/dist/quill.snow.css";
// import "quill/dist/quill.bubble.css";
import Back from "./component/back/index.vue";
// Vue.use(VueQuillEditor);
Vue.component("back-before", Back);
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.mixin(minix);
new Vue({
  render: (h) => h(App),
  store,
  router,
}).$mount("#app");
