<!--
 * @Author: your name
 * @Date: 2022-04-11 14:00:24
 * @LastEditTime: 2022-04-11 15:45:56
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /惠旅云底座/huilvyun_kaifangzhePlatform/src/App.vue
-->
<template>
  <div id="app">
    <i-header v-if="hasHeader" :type="category" />
    <router-view class="container" />
    <i-footer v-if="hasFooter" />
  </div>
</template>

<script>
import IHeader from "@component/IHeader/index.vue";
import IFooter from "@component/IFooter/index.vue";
import { mapGetters } from "vuex";
export default {
  name: "App",
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["userInfo"]),
    category() {
      return this.userInfo?.categoryId || "";
    },
    hasHeader() {
      return this.$route.meta.hasHeader;
    },
    hasFooter() {
      return this.$route.meta.hasFooter;
    },
  },
  components: {
    [IHeader.name]: IHeader,
    [IFooter.name]: IFooter,
  },
};
</script>
<style scoped lang="scss">
* {
  user-select: none;
}
html,
body {
  background-color: #f5f5f5;
}
#app {
  width: 100%;
  min-height: 100%;
  background-color: #f5f5f5;
}
.hasSub + .container {
  padding-top: 130px;
}
::v-deep .container {
  padding: 80px 0px 20px;
  box-sizing: border-box;
  width: 1200px;
  margin: 0 auto;
}
:v-deep .el-upload-list__item.is-success.focusing .el-icon-close-tip {
  display: none !important;
}
</style>
