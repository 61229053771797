import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: "",
    userInfo: null,
    tenementList: [],
    tenement: null
  },
  mutations: {
    logOut(state) {
      sessionStorage.clear();
      state.token = "";
      state.userInfo = null;
    },
    storeToken(state, e) {
      state.token = e;
      sessionStorage.setItem("token", e);
    },
    storeUserInfo(state, e) {
      state.userInfo = { ...e };
      sessionStorage.setItem("userInfo", JSON.stringify(e));
    },
    storeTenementList(state, e) {
      state.tenementList = e || [];
      sessionStorage.setItem("tenementList", JSON.stringify(e || []));
    },
    storeTenement(state, e) {
      state.tenement = e || [];
      sessionStorage.setItem("tenement", JSON.stringify(e || ''));
    },
  },
  getters: {
    token(state) {
      if (!state.token) {
        state.token = sessionStorage.getItem("token") || "";
      }
      return state.token;
    },
    userInfo(state) {
      if (!state.userInfo) {
        let cache = sessionStorage.getItem("userInfo");
        state.userInfo = cache ? JSON.parse(cache) : null;
      }
      return state.userInfo;
    },
    tenementList(state) {
      if (!state.tenementList.length) {
        let cache = sessionStorage.getItem("tenementList");
        state.tenementList = cache ? JSON.parse(cache) : [];
      }
      return state.tenementList;
    },
    tenement(state) {
      if (!state.tenement) {
        let cache = sessionStorage.getItem("tenement");
        state.tenement = cache ? JSON.parse(cache) : null
      }
      return state.tenement;
    },
  },
  actions: {},
  modules: {},
});
