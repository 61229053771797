/*
 * @Author: your name
 * @Date: 2022-04-11 16:03:04
 * @LastEditTime: 2022-04-11 16:27:32
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /惠旅云底座/huilvyun_kaifangzhePlatform/src/component/back/index.js
 */
export default {
  name: "back-before",
  props: {
    // 标题
    title:{
      type:String,
      default:''
    }
  },

  components: {},

  data() {
    return {};
  },

  methods: {
    //返回上一页
    backBefor() {
      this.$router.back();
    },
  },
};
