/*
 * @Author: your name
 * @Date: 2022-04-25 14:14:35
 * @LastEditTime: 2022-04-26 09:10:51
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /huilvyun_kaifangzhePlatform/src/utils/minix.js
 */
import { getDictData } from "@lib";
export default {
  data: () => ({
    dict: {},
  }),
  computed: {
    routerParams() {
      return JSON.parse(localStorage.getItem("routerParams"));
    },
  },
  methods: {
    //清掉多条sessionStorage
    sessionStorageRemove(list = []) {
      list.forEach((item) => sessionStorage.removeItem(item));
    },
    /**
     * 树递归解析
     */
    arraytoTree(list) {
      return list
        .filter((m) => !list.find((n) => m.pId === n.id))
        .map((m) => this.atoTree(m, list));
    },
    /**
     * 树递归解析
     */
    atoTree(node, list) {
      let obj = {
        ...node,
      };
      let children = list.filter((m) => m.pId === node.id);
      if (children.length) {
        obj.children = children.map((m) => this.atoTree(m, list));
      }
      return obj;
    },
    /**
     * 初始话级联选项
     * @param {Array} data 级联列表
     * @param {String} key 级联标识
     * @returns
     */
    initCascader(data, key = "pid", fId, p = []) {
      if (!data.length) return data;
      let arr = [];
      if (fId) {
        const old = data.filter((item) => item[key] === fId);
        arr = old.map((item) => {
          if (item[key] === fId) {
            item.p = [...p, fId];
            const children = this.initCascader(
              data.filter((item) => item.id !== fId),
              key,
              item.id,
              item.p
            );
            if (children.length) {
              item.children = children;
            }
            item.value = item.id;
            item.label = item.name;
            return item;
          }
        });
      }
      return arr;
    },
    /**
     * 获取数据字典
     * @param {*} e 字典名称
     */ initDictData(e) {
      return new Promise((resolve) => {
        if (this.dict[e]) {
          resolve(this.dict[e]);
        } else {
          getDictData(e).then((res) => {
            let data = res.data || res.result;
            if (data) {
              this.$set(
                this.dict,
                e,
                Object.freeze(
                  data.map((m) => ({ ...m, id: m.code, label: m.name }))
                )
              );
              resolve(this.dict[e]);
            } else {
              resolve([]);
            }
          });
        }
      });
    },
    /**
     * 字典id过滤选项名称
     * @param {String} name 数据字典名称
     * @param {String} id 数据字典选项id
     * @returns
     */
    getDictName(name, id) {
      return (
        (this.dict[name] && this.dict[name].find((m) => m.id == id)?.label) ||
        ""
      );
    },
    //获取sess
    sessGet(code) {
      const value = sessionStorage.getItem(code);
      sessionStorage.removeItem(code);
      return value;
    },
  },
};
