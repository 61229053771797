import qs from "querystring";
import { Post, Get, Upload } from "@lib/request";
import { oldBase } from "./config";
/**
 * 获取数据字典
 * @param {String} modular 模块名
 * @param {String} name 字典名
 * @returns
 */
export const getDictData = (e) => {
  return Get(oldBase + `/application/getDicList?dictId=`+e);
};

/**
 * 获取token
 */
const getToken = (data) => Post(oldBase + "/auth/getToken", data);
/**
 * 找回密码
 */
const findPassword = (data) => Post(oldBase + "/user/findPassword", data);
const changePassword = (data) => Post(oldBase + "/user/changePassword", data);
/**
 * 发送验证码
 */
const sendCode = (data) => Post(oldBase + "/user/sendCode", data);
/**
 * 校验验证码
 */
const checkVerifyCode = (data) => Post(oldBase + "/user/checkVerifyCode", data);

/**
 * 获取用户信息
 */
const getUserInfo = () => Post(oldBase + "/user/getUserInfo");
/**
 * 注册接口
 */
const userRegister = (data) => Post(oldBase + "/user/register", data);
/**
 * 新增/修改解决方案
 */
const addOrEdit = (data) => Post(oldBase + "/solutionDoc/addOrEdit", data);
/**
 * 上架/下架解决方案
 */
const OnOrOffShelf = (data) => Get(oldBase + "/solutionDoc/OnOrOffShelf", data);
/**
 * 删除解决方案
 */
const solutionDocDel = (data) => Get(oldBase + "/solutionDoc/del", data);
/**
 * 获取解决方案类型
 */
const getType = () => Get(oldBase + "/solutionDoc/getType");
/**
 * 获取解决方案列表
 */
const getList = (data) => Get(oldBase + "/solutionDoc/list", data);

/**
 * 提交审核解决方案
 */
const solutionDocSubmit = (data) => Get(oldBase + "/solutionDoc/submit", data);
/**
 * 浏览解决方案
 */
const glanceView = (data) => Get(oldBase + "/solutionDoc/view", data);

/**
 * 新增应用
 */
const applicationAdd = (data) => Post(oldBase + "/application/add", data);

/**
 * 修改应用
 */
const applicationUpdate = (data) => Post(oldBase + "/application/update", data);
/**
 * icon图标
 */
const getIconAll = (data) => Post(oldBase + "/application/getIconAll", data);
/**
 * 获取应用分类
 */
const getAppType = (data) => Post(oldBase + "/application/getAppType", data);
/**
 * 适用平台
 */
const getPlatAll = (data) => Post(oldBase + "/application/getPlatAll", data);
/**
 * 文件上传
 */
const fileUploader = (data, id) =>
  Upload(
    `/fileUploader/upload.json?categoryId=devCenter_docAnnex&subsysId=developCenter&businessId=${id}`,
    data
  );

/**
 * 文件删除
 */
const fileDelete = (id) => Post(oldBase + `/file/del?id=${id}`);
/**
 * 图片上传
 */
const ImgUploader = (data, id) =>
  Upload(
    `/fileUploader/upload.json?categoryId=devCenter_businessLicense&subsysId=developCenter&businessId=${id}`,
    data
  );
/**
 * 富文本文件上传
 */
const fwenbenUploader = (data) =>
  Upload(
    "/fileUploader/upload.json?categoryId=ueditor&subsysId=developCenter",
    data
  );
/**
 * 文件删除
 */
const fileRemove = (data) => Upload("/fileUploader/remove.json", data);
/**
 * 查询租户帐号
 *   {tenement: string}
 */
const checkTenement = (data) =>
  Post(oldBase + "/developer/checkTenement", data);
/**
 * 绑定租户列表
 *   {developerId: string}
 */
const developerList = (data) => Post(oldBase + "/developer/list", data);
/**
 * 查询配置
 *   {tenementId: string}
 */
const queryConfig = (data) => Post(oldBase + "/developer/queryConfig", data);
/**
 * 保存配置
 *   {
  "authUrl": "",
  "id": "",
  "orgAddUrl": "",
  "orgDelUrl": "",
  "orgUpdateUrl": "",
  "roleAddUrl": "",
  "roleDelUrl": "",
  "roleUpdateUrl": "",
  "tenementAddUrl": "",
  "tenementDelUrl": "",
  "tenementId": "",
  "tenementRoleAddUrl": "",
  "tenementRoleDelUrl": "",
  "tenementRoleUpdateUrl": "",
  "tenementUpdateUrl": "",
  "token": "",
  "userAddUrl": "",
  "userDelUrl": "",
  "userRoleAddUrl": "",
  "userRoleDelUrl": "",
  "userRoleUpdateUrl": "",
  "userUpdateUrl": ""
}
 */
const saveConfig = (data) => Post(oldBase + "/developer/saveConfig", data);
/**
 * 提交绑定申请
 *   {
  "applications": [],
  "tenement": ""
}
 */
const developerSubmit = (data) => Post(oldBase + "/developer/submit", data);
/**
 * 删除租户绑定
 *   {
  "id": string
}
 */
const developerDel = (data) => Post(oldBase + "/developer/del", data);

//旅游大数据配置api说明
/**
 * 大屏状态列表
 */
const stateList = () => Post(oldBase + "/bigscreen/api/stateList");
/**
 * 数据源类型
 */
const dataTypeList = () => Post(oldBase + "/bigscreen/api/dataTypeList");
/**
 * 模版列表
 */
const templateList = () => Post(oldBase + "/bigscreen/api/templateList");
/**
 * 大屏列表
 */
const bigScreenList = () => Post(oldBase + "/bigscreen/api/bigScreenList");
/**
 * add大屏
 *   {
  id:"id",
  zhName:"zh名称",
  enName:"en名称",
  templateId:"模版id",
  templateName:"模版名称",
  type:"类型"
}
 */
const addBigScreen = (data) =>
  Post(oldBase + "/bigscreen/api/addBigScreen", data);
/**
 * edit大屏
 *   {
  id:"id",
  zhName:"zh名称",
  enName:"en名称",
  templateId:"模版id",
  templateName:"模版名称",
  type:"类型"
}
 */
const editBigScreen = (data) =>
  Post(oldBase + "/bigscreen/api/editBigScreen", data);
/**
 * del大屏
 *   {
application/x-www-form-urlencoded

String id:"大屏id"
}
 */
const delBigScreen = (data) =>
  Post(oldBase + `/bigscreen/api/delBigScreen?${qs.stringify(data)}`);
/**
 * get大屏
 *   {
application/x-www-form-urlencoded

String id:"大屏id"
}
 */
const getBigScreen = (data) =>
  Post(oldBase + `/bigscreen/api/getBigScreen?${qs.stringify(data)}`);
/**
 * get大屏
 *   {
application/x-www-form-urlencoded

String webId:"大屏id",boolean needInterface:"是否需要返回接口数据"
}
 */
const interfaceCategoryList = (data) =>
  Post(oldBase + `/bigscreen/api/interfaceCategoryList?${qs.stringify(data)}`);
/**
 * add接口
 *   {
    id:"id",
    categoryId:"分类id",
    name:"名称",
    code:"code",
    interfaceUrl:"接口地址",
    interfaceScript:"真实数据脚本"
    modelScript:"模拟数据脚本"
    dataType:"数据类型1：真实，0：模拟"
    remark:"备注"
    webId:"大屏id"
}
 */
const addInterface = (data) =>
  Post(oldBase + "/bigscreen/api/addInterface", data);
/**
 * edit接口
 *   {
    id:"id",
    categoryId:"分类id",
    name:"名称",
    code:"code",
    interfaceUrl:"接口地址",
    interfaceScript:"真实数据脚本"
    modelScript:"模拟数据脚本"
    dataType:"数据类型1：真实，0：模拟"
    remark:"备注"
    webId:"大屏id"
}
 */
const editInterface = (data) =>
  Post(oldBase + "/bigscreen/api/editInterface", data);
/**
 * del接口
application/x-www-form-urlencoded

String id:"id"
 */
const delInterface = (data) =>
  Post(oldBase + `/bigscreen/api/delInterface?${qs.stringify(data)}`);
/**
 * del接口
application/x-www-form-urlencoded

String id:"id"
 */
const getInterface = (data) =>
  Post(oldBase + `/bigscreen/api/getInterface?${qs.stringify(data)}`);
export {
  getToken,
  findPassword,
  changePassword,
  sendCode,
  checkVerifyCode,
  getUserInfo,
  userRegister,
  addOrEdit,
  OnOrOffShelf,
  solutionDocDel,
  getType,
  getList,
  solutionDocSubmit,
  glanceView,
  fileUploader,
  ImgUploader,
  fwenbenUploader,
  fileRemove,
  checkTenement,
  developerList,
  queryConfig,
  saveConfig,
  developerSubmit,
  developerDel,
  applicationAdd,
  applicationUpdate,
  getAppType,
  getPlatAll,
  stateList,
  dataTypeList,
  templateList,
  bigScreenList,
  addBigScreen,
  editBigScreen,
  delBigScreen,
  getBigScreen,
  interfaceCategoryList,
  addInterface,
  editInterface,
  delInterface,
  getInterface,
  getIconAll,
  fileDelete,
};
