import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

const routes = [
  {
    path: "/",
    redirect: "/login",
    meta: {
      hasHeader: true,
      hasFooter: true,
    },
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@view/register/index.vue"),
    meta: {
      hasHeader: true,
      hasFooter: true,
    },
  },
  // 登录页面
  {
    path: "/login",
    name: "login",
    component: () => import("@view/login/index.vue"),
    meta: {
      hasHeader: false,
      hasFooter: true,
    },
  },
  // 找回密码
  {
    path: "/reset",
    name: "reset",
    component: () => import("@view/reset/index.vue"),
    meta: {
      hasHeader: true,
      hasFooter: true,
    },
  },

  // * 合作商
  // 解决方案
  {
    path: "/solution/list",
    name: "solutionList",
    component: () => import("@partner/solution/solutionList/index.vue"),
    meta: {
      hasHeader: true,
    },
  },
  {
    path: "/solution/list/add",
    name: "solutionAdd",
    component: () => import("@partner/solution/solutionAdd/index.vue"),
    meta: {
      hasHeader: true,
    },
  },
  {
    path: "/solution/list/detail",
    name: "solutionDetail",
    component: () => import("@partner/solution/solutionDetail/index.vue"),
    meta: {
      hasHeader: true,
    },
  },

  // 应用接入
  {
    path: "/apply/list",
    name: "applyList",
    component: () => import("@partner/apply/applyList/index.vue"),
    meta: {
      hasHeader: true,
    },
  },
  {
    path: "/apply/gaishu",
    name: "applyGaishu",
    component: () => import("@partner/apply/applyGaishu/index.vue"),
    meta: {
      hasHeader: true,
    },
  },
  {
    path: "/apply/list/add",
    name: "applyAdd",
    component: () => import("@partner/apply/applyAdd/index.vue"),
    meta: {
      hasHeader: true,
    },
  },
  {
    path: "/apply/list/leader",
    name: "applyLeader",
    component: () => import("@partner/apply/applyLeader/index.vue"),
    meta: {
      hasHeader: true,
    },
  },
  {
    path: "/apply/list/menu",
    name: "applyMenu",
    component: () => import("@partner/apply/apply-menu/index.vue"),
    meta: {
      hasHeader: true,
      title: "应用菜单注册",
    },
  },
  {
    path: "/apply/list/menu-add",
    name: "applyMenuAdd",
    component: () => import("@partner/apply/apply-menu-add/index.vue"),
    meta: {
      hasHeader: true,
      title: "新增菜单",
    },
  },
  {
    path: "/apply/list/use",
    name: "applyUse",
    component: () => import("@partner/apply/apply-use/index.vue"),
    meta: {
      hasHeader: true,
      title: "应用功能注册",
    },
  },
  {
    path: "/apply/list/use-add",
    name: "applyUseAdd",
    component: () => import("@partner/apply/apply-use-add/index.vue"),
    meta: {
      hasHeader: true,
      title: "新增功能",
    },
  },
  // *  开发者
  {
    path: "/account/list",
    name: "accountList",
    component: () => import("@developer/account/list/index.vue"),
    meta: {
      hasHeader: true,
    },
  },
  {
    path: "/account/bind",
    name: "accountBind",
    component: () => import("@developer/account/bind/index.vue"),
    meta: {
      hasHeader: true,
    },
  },
  {
    path: "/account/detail",
    name: "accountDetail",
    component: () => import("@developer/account/detail/index.vue"),
    meta: {
      hasHeader: true,
    },
  },
  {
    path: "/application/base",
    name: "applicationBase",
    component: () => import("@developer/application/base/index.vue"),
    meta: {
      hasHeader: true,
    },
  },
  {
    path: "/application/data",
    name: "applicationData",
    component: () => import("@developer/application/data/index.vue"),
    meta: {
      hasHeader: true,
    },
  },
  {
    path: "/application/add",
    name: "applicationAdd",
    component: () => import("@developer/application/add/index.vue"),
    meta: {
      hasHeader: true,
    },
  },
  {
    path: "/application/edit",
    name: "applicationEdit",
    component: () => import("@developer/application/edit/index.vue"),
    meta: {
      hasHeader: true,
    },
  },
  {
    path: "/application/setting",
    name: "applicationSetting",
    component: () => import("@developer/application/setting/index.vue"),
    meta: {
      hasHeader: true,
    },
  },
  {
    path: "/document",
    name: "document",
    component: () => import("@developer/document/index.vue"),
    meta: {
      hasHeader: true,
    },
  },
];

const router = new Router({ routes });

router.beforeEach((to, from, next) => {
  const token = sessionStorage.getItem("token");
  console.log(Object.keys(to.params).length);
  Object.keys(to.params).length &&
    localStorage.setItem("routerParams", JSON.stringify(to.params));

  if (!token) {
    if (to.path == "/login" || to.path == "/register" || to.path == "/reset") {
      next();
    } else {
      next("/login");
    }
  } else {
    next();
  }
});

export default router;
